import { useState, useEffect } from 'react'

export default function useDraggable(child: any) {
  const [offset, setOffset] = useState({ dx: 0, dy: 0 })
  const { dx, dy } = offset

  useEffect(() => {
    const handleMouseDown = (event: any) => {
      const startX = event.pageX - dx
      const startY = event.pageY - dy

      const handleMouseMove = (event: any) => {
        const newDx = event.pageX - startX
        const newDy = event.pageY - startY
        setOffset({ dx: newDx, dy: newDy })
      }

      document.addEventListener('mousemove', handleMouseMove)

      document.addEventListener(
        'mouseup',
        () => {
          document.removeEventListener('mousemove', handleMouseMove)
        },
        { once: true },
      )
    }

    child.current.addEventListener('mousedown', handleMouseDown)

    return () => {
      child.current.removeEventListener('mousedown', handleMouseDown)
    }
  }, [dx, dy])

  useEffect(() => {
    child.current.style.transform = `translate3d(${dx}px, ${dy}px, 0)`
  }, [dx, dy])
}
