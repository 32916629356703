import React, { ReactElement, useEffect, useState, useRef } from 'react'
import { graphql } from 'gatsby'
import { saveAs } from 'file-saver'

import './PropertyLocation.scss'
import MinusIcon from '../../assets/images/icons/minus-icon.png'
import PlusIcon from '../../assets/images/icons/plus-icon.png'
import Seo from '../../components/Seo/Seo'
import download from '../../assets/svg/download_vicinity.svg'
import map from '../../assets/svg/vicinity_map.svg'
import useDraggable from '../../hooks/useDraggable'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import loadable from '@loadable/component'

const Layout = loadable(() => import('../../components/Layout/Layout'))
const PropertyNavigationSliced = loadable(() =>
  import('../../components/PropertyNavigationSliced/PropertyNavigationSliced'),
)
const OutdoorMap = loadable(() =>
  import('../../components/OutdoorMap/OutdoorMap'),
)
const LinkButton = loadable(() =>
  import('../../components/LinkButton/LinkButton'),
)
const FullModal = loadable(() => import('../../components/FullModal/FullModal'))
const Modal = loadable(() => import('../../components/Modal/Modal'))

type TowerTypes = {
  name: string
  locationLandmarks?: {
    seoLocationTitle: string
    seoLocationDescription: string
    locationBrief: string
    landmarkdsDefault: string
    locationDefault: string
    locationSpecific: string
    latitude: string
    longitude: string
    landmarksEstablishment?: {
      items: {
        establishmentName: string
        establishmentBody: string
        establishmentDistance: string
        establishmentLocation: string
        establishmentLatitude: string
        establishmentLongitude: string
        imagesGallery: {
          description: string
          url: string
          featured: boolean
        }[]
      }[]
    }
  }
  overviewDetails: {
    panoramas?: {
      url: string
      featured: string
    }[]
  }
}

type PropertyDataTypes = {
  id: string
  originalId: string
  name: string
  overviewDefault: string
  slug: string
  logo: string
  projectName: string
  propertyPrice: string
  location: string
  propertySizeFloorArea: string
  propertySizeLotArea: string
  propertyType: string
  propertyPriceRangeHighest: string
  propertyPriceRangeLowest: string
  image1: string
  image2: string
  image3: string
  mapImage: string
  towers: TowerTypes[]
}

type NearbyEstablishmentsProps = {
  name: string
  image?: string
  position: {
    lat: number
    lng: number
  }
}

type PropertyLocationPropTypes = {
  data: {
    propertyData: PropertyDataTypes
  }
}

const PropertyLocation = ({
  data: { propertyData },
}: PropertyLocationPropTypes): ReactElement => {
  const [mainTower] = useState<TowerTypes | null>(
    propertyData.towers ? propertyData.towers[0] || null : null,
  )

  const aerialView = mainTower?.overviewDetails?.panoramas
    ? mainTower?.overviewDetails?.panoramas[0]?.url
    : null

  const [nearbyLocations] = useState<NearbyEstablishmentsProps[] | null>(
    mainTower?.locationLandmarks?.landmarksEstablishment?.items
      ? mainTower?.locationLandmarks?.landmarksEstablishment?.items?.map(
          (establishment) => ({
            name: establishment.establishmentName,
            image: establishment?.imagesGallery
              ? establishment?.imagesGallery[0].url
              : undefined,
            position:
              {
                lat: parseFloat(establishment.establishmentLatitude),
                lng: parseFloat(establishment.establishmentLongitude),
              } || null,
          }),
        )
      : null,
  )
  const [nearbyLocationsPin, setNearbyLocationPin] = useState<
    NearbyEstablishmentsProps[]
  >([])
  const [nearbyLocationsInfoWindow, setNearbyLocationInfoWindow] = useState<
    NearbyEstablishmentsProps[]
  >([])
  const [selectedNearbyLocation, setSelectedNearbyLocation] = useState<
    string[]
  >([])
  const [isModalShowing, setIsModalShowing] = useState<boolean>(false)
  const [isLocationDetailsShowing, setIsLocationDetailsShowing] = useState<
    boolean
  >(false)
  const [zoomLvl, setZoomLvl] = useState(1)
  const cardRef = useRef(null)

  const DraggableCard = ({ children }: any) => {
    if (zoomLvl > 1) {
      useDraggable(cardRef)
    }

    return (
      <div className="card" ref={cardRef}>
        {children}
      </div>
    )
  }

  useEffect(() => {
    if (nearbyLocations) {
      setNearbyLocationPin(
        nearbyLocations.filter(
          (nearbyLocation) =>
            !selectedNearbyLocation.includes(nearbyLocation.name),
        ),
      )
      setNearbyLocationInfoWindow(
        nearbyLocations.filter((nearbyLocation) =>
          selectedNearbyLocation.includes(nearbyLocation.name),
        ),
      )
    }
  }, [selectedNearbyLocation, nearbyLocations])

  const toggleActiveNearbyLocation = (nearbyLocation: string): void => {
    const currentActive = [...selectedNearbyLocation]
    if (selectedNearbyLocation.includes(nearbyLocation)) {
      currentActive.splice(currentActive.indexOf(nearbyLocation), 1)

      return setSelectedNearbyLocation(currentActive)
    }

    return setSelectedNearbyLocation([...currentActive, nearbyLocation])
  }

  const handleDownloadMap = (): void => {
    if (propertyData.mapImage) {
      saveAs(propertyData.mapImage, 'vicinity-map')
    }
  }

  const seoTitle = propertyData.towers[0]?.locationLandmarks?.seoLocationTitle
  const defaultTitle = `${propertyData.name} | Location`

  const seoDescription =
    propertyData.towers[0]?.locationLandmarks?.seoLocationDescription

  return (
    <Layout>
      <Seo
        title={seoTitle ? seoTitle : defaultTitle}
        jsonData={{
          '@type': 'Apartment',
          description: seoDescription
            ? seoDescription
            : propertyData.overviewDefault,
        }}
      />
      <div className="property-location">
        <PropertyNavigationSliced
          propertyType={propertyData.propertyType}
          propertySlug={propertyData.slug}
          propertyLocation={propertyData.location}
        />
        <div className="property-location-details">
          <h4 className="title">
            {mainTower?.locationLandmarks?.locationBrief}
          </h4>
          <div
            dangerouslySetInnerHTML={{
              __html: mainTower
                ? `${mainTower?.locationLandmarks?.landmarkdsDefault}`
                : '',
            }}
          />
          {!!nearbyLocations && (
            <>
              <h3 className="nearby-establishments-title">
                Nearby Establishments
              </h3>
              <ul className="nearby-establishments-list">
                {nearbyLocations.slice(0, 4).map((establishment) => (
                  <li key={establishment.name}>
                    <button
                      type="button"
                      onClick={(): void =>
                        toggleActiveNearbyLocation(establishment.name)
                      }
                      className={
                        selectedNearbyLocation.includes(establishment.name)
                          ? 'active'
                          : ''
                      }
                    >
                      {establishment?.image && (
                        <LazyLoadImage
                          src={establishment?.image || ''}
                          alt=""
                        />
                      )}
                      <span>{establishment.name}</span>
                    </button>
                  </li>
                ))}
              </ul>
            </>
          )}
          {nearbyLocations?.length && nearbyLocations.length > 3 ? (
            <LinkButton
              to={`/locations/${propertyData.location}/guide`}
              primary
              className="explore-neighborhood-button"
            >
              View More Nearby Establishments
            </LinkButton>
          ) : (
            ''
          )}
        </div>
        <div className="property-location-map">
          <OutdoorMap
            id="map"
            zoom={15}
            center={{
              lat: parseFloat(mainTower?.locationLandmarks?.latitude || ''),
              lng: parseFloat(mainTower?.locationLandmarks?.longitude || ''),
            }}
            options={{
              zoomControl: true,
            }}
            nearbyLocationsInfoWindows={nearbyLocationsInfoWindow}
            nearbyLocationsPins={nearbyLocationsPin}
            toggleNearbyLocation={toggleActiveNearbyLocation}
          />
          <div className="map-link-container">
            <h4>{mainTower?.locationLandmarks?.locationSpecific}</h4>
            <a
              href={`https://www.google.com/maps/@${mainTower?.locationLandmarks?.latitude},${mainTower?.locationLandmarks?.longitude},15z`}
              target="_blank"
              rel="noreferrer"
            >
              Go to Google Map
            </a>
          </div>
          <div className="view-button">
            <button
              type="button"
              onClick={(): void => setIsLocationDetailsShowing(true)}
            >
              <LazyLoadImage src={map} alt="view-plan" />
              <p>View Vicinity Map</p>
            </button>
            <button type="button" onClick={handleDownloadMap}>
              <LazyLoadImage src={download} alt="view-plan" />
              <p>Download Vicinity Map</p>
            </button>
            <FullModal
              className="left"
              side
              isShowing={isLocationDetailsShowing}
              onClose={(): void => {
                setIsLocationDetailsShowing(false)
                setZoomLvl(1)
              }}
            >
              <div className="zoom-btns">
                <LazyLoadImage
                  style={{ borderBottom: '1px solid #c5c5c6' }}
                  src={PlusIcon}
                  alt="+"
                  onClick={() =>
                    setZoomLvl((prev) => {
                      if (prev * 1.25 > 3) {
                        return prev
                      }
                      return prev * 1.25
                    })
                  }
                />
                <LazyLoadImage
                  src={MinusIcon}
                  alt="-"
                  onClick={() =>
                    setZoomLvl((prev) => {
                      if (prev / 1.25 < 1) {
                        return 1
                      }
                      return prev / 1.25
                    })
                  }
                />
              </div>
              <div className="view-plan-modal">
                <DraggableCard>
                  <LazyLoadImage
                    className="site-dev-plan"
                    style={{ transform: `scale(${zoomLvl})` }}
                    src={propertyData?.mapImage}
                  />
                  <div style={{ transform: `scale(${zoomLvl})` }}></div>
                </DraggableCard>
              </div>
            </FullModal>
          </div>
        </div>
      </div>
      {/* mobile view starts here */}
      <div className="property-location-mobile">
        <div className="property-location-map">
          <OutdoorMap
            id="map"
            zoom={15}
            center={{
              lat: parseFloat(mainTower?.locationLandmarks?.latitude || ''),
              lng: parseFloat(mainTower?.locationLandmarks?.longitude || ''),
            }}
            options={{
              zoomControl: true,
            }}
            nearbyLocationsInfoWindows={nearbyLocationsInfoWindow}
            nearbyLocationsPins={nearbyLocationsPin}
            toggleNearbyLocation={toggleActiveNearbyLocation}
          />
          <div className="map-link-container">
            <h4>{mainTower?.locationLandmarks?.locationSpecific}</h4>
            <a
              href={`https://www.google.com/maps/@${mainTower?.locationLandmarks?.latitude},${mainTower?.locationLandmarks?.longitude},15z`}
              target="_blank"
              rel="noreferrer"
            >
              Go to Google Map
            </a>
          </div>
        </div>
        <PropertyNavigationSliced
          propertyType={propertyData.propertyType}
          propertySlug={propertyData.slug}
          propertyLocation={propertyData.location}
        />
        <div className="property-location-details">
          <h4 className="title">
            {mainTower?.locationLandmarks?.locationBrief}
          </h4>
          <div
            dangerouslySetInnerHTML={{
              __html: mainTower
                ? `${mainTower?.locationLandmarks?.landmarkdsDefault}`
                : '',
            }}
          />
          {!!nearbyLocations && (
            <>
              <h3 className="nearby-establishments-title">
                Nearby Establishments
              </h3>
              <ul className="nearby-establishments-list">
                {nearbyLocations.slice(0, 4).map((establishment) => (
                  <li key={establishment.name}>
                    <button
                      type="button"
                      onClick={(): void =>
                        toggleActiveNearbyLocation(establishment.name)
                      }
                      className={
                        selectedNearbyLocation.includes(establishment.name)
                          ? 'active'
                          : ''
                      }
                    >
                      {establishment?.image && (
                        <LazyLoadImage
                          src={establishment?.image || ''}
                          alt=""
                        />
                      )}
                      <span>{establishment.name}</span>
                    </button>
                  </li>
                ))}
              </ul>
            </>
          )}

          {nearbyLocations?.length && nearbyLocations.length > 3 ? (
            <LinkButton
              to={`/neighborhood-guide/${propertyData.slug}`}
              primary
              className="explore-neighborhood-button"
            >
              View More Nearby Establishments
            </LinkButton>
          ) : (
            ''
          )}

          <div className="view-button">
            <button type="button" onClick={(): void => setIsModalShowing(true)}>
              <LazyLoadImage src={map} alt="view-plan" />
              <p>View Vicinity Map</p>
            </button>
            <button type="button" onClick={handleDownloadMap}>
              <LazyLoadImage src={download} alt="view-plan" />
              <p>Download Vicinity Map</p>
            </button>
            <Modal
              className="left"
              noIcons
              side
              isShowing={isModalShowing}
              onClose={(): void => setIsModalShowing(false)}
            >
              <div className="view-plan-modal">
                <LazyLoadImage
                  className="site-dev-plan"
                  src={propertyData.mapImage}
                  alt=""
                />
              </div>
            </Modal>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default PropertyLocation

export const pageQuery = graphql`
  query PropertyLocationQuery($id: String!) {
    propertyData: property(id: { eq: $id }) {
      ...PropertyPageFields
    }
  }
`
